// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import ApiService from '../../../services/api';
import { Container, Card, CardBody, CardText, CardTitle } from 'design-react-kit';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { LocalSettingsContext } from "services/local_settings";
import 'swiper/css';
import 'swiper/css/pagination';

import moment from 'moment';
import 'moment/locale/it';


const WidgetCalendar = (props) => {

    const settings = useContext(LocalSettingsContext);

    const localSettings = settings.localSettings;
        
    const eventsParentCategorySlug = localSettings.events_parent_category === null ? 'novita' : localSettings.events_parent_category_data.slug

    const [widget] = useState(props.widget);

    const days = [];
    for (let i = 0; i < 10; i++) {
        days.push([
            moment().add(i, 'days').format('D'),
            moment().add(i, 'days').format('dddd'),
            moment().add(i, 'days').format('YYYY-MM-DD')
        ]);
    }

    const [ events, setEvents ] = useState();

    const fetchEvents = async() => {
        const eventsData = await ApiService.getEventsList();
        setEvents(eventsData);
    }

    useEffect(() => {
        fetchEvents();
    }, []);

    let slidesPerView;
    const width = window.innerWidth;
    if(width <= 506) {
        slidesPerView = 1;
    } else if((width >= 507) && width <= 1029 ) {
        slidesPerView = 2;
    } else if(width > 1029) {
        slidesPerView = 5;
    }
    
    return <>
        <Container className="mt-5">     
            <h2 className="mb-4">{widget.data.label ? widget.data.label : 'Eventi'}</h2>   
            <div className="it-header-block">
                <div className="it-header-block-title calendar-header-block">
                    <p className="mb-0 text-center calendar-header-title">{moment().format('MMMM YYYY')}</p>
                </div>
            </div>        
            <Swiper slidesPerView={slidesPerView} modules={[Pagination]} pagination={{ clickable: true }}>
                {days.map((day, i) => 
                    <SwiperSlide key={"key_slideItem_" + i}>
                        <div className="item">
                            <div className="it-single-slide-wrapper">                    
                                <Card>
                                    <CardBody>
                                        <CardTitle className="calendar-day-header mb-5">
                                            <p className="calendar-day-big">{day[0]}</p>
                                            <div className="small calendar-small-day-name">{day[1]}</div>
                                        </CardTitle>
                                        <div className="swiper-content">
                                    {events && events.map((event, j) => {
                                        let events = null;

                                        const isTimelineEvent = event.content.timeline.length > 0;

                                        if (
                                            (isTimelineEvent &&
                                                day[2] >= moment(event.content.timeline[0].date).format('YYYY-MM-DD') &&
                                                day[2] <= moment(event.content.timeline[event.content.timeline.length - 1].date).format('YYYY-MM-DD')) ||
                                            (!isTimelineEvent && day[2] === moment(event.date_event).format('YYYY-MM-DD'))
                                        ) {
                                            events = (
                                                <CardText key={"key_eventLink_" + j}>
                                                    <a href={eventsParentCategorySlug === 'novita'
                                                        ? '/novita/eventi/' + event.category.slug + '/' + event.slug + '/'
                                                        : '/eventi/' + event.category.slug + '/' + event.slug + '/'
                                                        }
                                                    >
                                                        {event.title}
                                                    </a>
                                                </CardText>
                                            );
                                        }

                                        return events;
                                    })}
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>   
                    </SwiperSlide>                     
                )}
            </Swiper>
        </Container>
    </>;
}

export default WidgetCalendar;